import Auth from './Auth'
import Test1 from "./components/Test1"; // Adjust the import path as necessary
import Test2 from "./components/Test2"; // Adjust the import path as necessary

//eslint-disable-next-line
export default {
  path: "/auth/",
  element: <Auth />,
  strict: true,
  children: [
    {
      path: "/auth/test1",
      element: <Test1 />,
      exact: true,
    },
    {
      path: "/auth/test2",
      element: <Test2 />,
      exact: true,
    },
  ],
};
