import React, { useState } from "react";
import { Menu, Dropdown, Input, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Typography from "../Typography";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import ExpandMoreRoundedIcon from "@ant-design/icons";

const { Search } = Input;

const workspaceColorMap = ["#4555eb", "#209f85", "#fa694a", "#2c3e50", "#e74c3c"];

const PickWorkspaceColor = (word) => {
  if (!word) return "#cccccc"; // Fallback color
  const firstChar = word.charAt(0)?.toLowerCase();
  const alphabetIndex = firstChar.charCodeAt(0) - "a".charCodeAt(0);
  const colorIndex = Math.floor(alphabetIndex / (26 / workspaceColorMap.length));
  return workspaceColorMap[colorIndex] || "#cccccc";
};

function formatString(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
}

const SearchDropdown = ({ workspaceList, handleSelectWorkspce, userData }) => {
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  // const [userData, setuserData] = useRecoilState(userInfo);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const filteredItems = (workspaceList || []).filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const menu = (
    <Menu>
      <Menu.Item key="search">
        <Input
          placeholder="Search workspaces"
          onChange={(e) => handleSearch(e.target.value)}
          onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing on input click
          style={{ height: 32 }}
        />
      </Menu.Item>
      {filteredItems.map((element) => (
        <Menu.Item key={element.id}>
          <div
            style={{ display: "flex", alignItems: "center", width: 170 }}
            onClick={() => handleSelectWorkspce(element)}
          >
            <Avatar
              size={22}
              style={{
                backgroundColor: PickWorkspaceColor(element.name),
                color: "#ffffff",
                marginRight: 6,
              }}
            >
              <Typography variant="xs" style={{ color: "white" }}>
                {`${element.name?.[0]?.toUpperCase()}${element.name?.[1]?.toUpperCase()}`}
              </Typography>
            </Avatar>
            <p
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                color: "var(--text-color-black)",
                fontSize: 12,
              }}
            >
              {formatString(element.name)}
            </p>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      open={open}
      onOpenChange={setOpen}
    >
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Avatar
          size={24}
          style={{
            backgroundColor: PickWorkspaceColor(
              userData?.workspaceInfo?.name
            ),
            color: "#ffffff",
            marginRight: 6,
          }}
        >
          {userData?.workspaceInfo?.name?.[0]?.toUpperCase()}
        </Avatar>
        <Typography variant="caption">
          {formatString(userData?.workspaceInfo?.name)}
        </Typography>
        <ExpandMoreRoundedIcon />
      </div>
    </Dropdown>
  );
};

export default SearchDropdown;
