import React from 'react';
import './HeroSection.scss';
import LandingPageImg from '../../../assets/images/qatar-landing-hero.jpeg';
import Clouds from '../../../assets/images/8918206.png'
import { Button , StatisticProps, Statistic} from 'antd';
import CountUp from 'react-countup';

const formatter = (value: number, suffix: string = '') => (
  <CountUp 
  end={value as number} 
  separator="," 
  formattingFn={(val) => `${val.toLocaleString()}${suffix}`}
  />
);



const GSTInvoiceRetrieval= () => {
  return (
    <div className="gst-invoice-container" id='hero-section'>
      <div className="invoice-image-container">
        <img className="invoice-image" src={LandingPageImg} alt="Qatar Airways Invoice Sample" />      
        <img className='hero-clouds' src={Clouds} alt="Clouds" />
        {/* <div className='landing-hero-filter'></div> */}
      </div>
      <div className="content">
      {/* <Button shape="round" style={{borderColor:'var(--primary-color)', color:'var(--primary-color)', width: '20%'}} className='hero-section-button'>
            Lorem Ipsum
          </Button> */}
        <h1>Effortlessly Retrieve GST Invoices from Qatar Finkraft Portal</h1>
        <p>
          Let Finkraft handle your GST input, so your employees can focus on
          business. With Finkraft ensuring Qatar Airways' invoices are GST
          compliant, your team can travel with peace of mind.
        </p>
        <div className="stats">
          <div className="stat-item">
          <Statistic  
          title={<span className="custom-statistic-title">Invoices Download</span>}
          value={80} 
          formatter={(value) => formatter(value as number, ' M')}
           className="custom-statistic-value"
          />
          </div>
          <div className="stat-item">
          <Statistic 
          title={<span className="custom-statistic-title" >Number of Users</span>}
           value={200} 
           formatter={(value) => formatter(value as number, '+')}
           className="custom-statistic-value"           />
          </div>
          <div className="stat-item">
          <Statistic 
           value={15}
           formatter={(value) => formatter(value as number, '+')}
           title={<span  className="custom-statistic-title" >Years of Service</span>}
           className="custom-statistic-value"           />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GSTInvoiceRetrieval;