import React, { useEffect, useState } from "react";
import MainImage from "../../assets/images/form-1&3.png";
import "./AddCompany.scss";
import { Button, Input, message } from "antd";
import ShieldIcon from "../../assets/images/shield-tick-safe (1).png";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE } from "app/shared/MasterLayout/masterlayout.constant";
import { useNavigate } from "react-router-dom";

const AddPanComponent: React.FC = () => {
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [PAN, setPAN] = useState("");
  // const [linkedinProfileLink, setLinkedinProfileLink] = useState('');
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   setName(userDetails.workspaceInfo?.name);
  //   setPAN(userDetails.pans?.[0]?.pan);
  //   setWebsite(userDetails?.workspaceInfo?.website);
  // }, [userDetails]);

  const handleSubmit = async () => {
    setLoading(true);
    // Handle form submission logic
    const payload = {
      company: name,
      pan: PAN,
      website: website,
      type: userDetails?.userInfo?.account_type,
    };

    const response = await apiPost(
      API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE,
      payload
    );
    if (response.status) {
      message.success("PAN has been added");
      console.log("pan has been added");
      setName("");
      setWebsite("");
      setPAN("");
      navigate("/app/userdetails");
    } else {
      message.error("Could not add PAN details");
      console.log("pan has not been added");
    }
    setLoading(false);
  };

  return (
    <div className="tax-manager-container">
      <div className="tax-manager-image-container">
        <img src={MainImage} alt="Tax Manager" className="tax-manager-image" />
        <div className="image-heading">Add Company</div>
        <div className="image-text">
          We would like to know about you as the invoice details are
          confidential. This will be a one time mandatory registration process
          and based on which you will be able to access/download the invoices
          after validation.
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="tax-manager-info-container">
          {/* <div className="skip-btn-container">
            <Button type="primary" className="tax-manager-skip-button">
              Skip
            </Button>
          </div> */}

          <div className="form-header">Enter Details</div>
          {/* <div className='form-group-container'> */}
          <div className="form-group">
            <label htmlFor="email">Company Name *</label>
            <Input
              type="text"
              defaultValue=" "
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter company name"
              style={{ borderColor: "#C3C4CD" }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Company Website *</label>
            <Input
              type="text"
              defaultValue=" "
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              placeholder="Enter company website"
              style={{ borderColor: "#C3C4CD" }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">PAN Number</label>
            <Input
              type="text"
              defaultValue=" "
              value={PAN}
              onChange={(e) => setPAN(e.target.value)}
              placeholder="Enter PAN Number"
              style={{ borderColor: "#C3C4CD" }}
            />
          </div>
          <div className="info-text">
            <img src={ShieldIcon} alt="shield icon" />
            <p>No spams your information is safe with us!</p>
          </div>
          <Button
            type="primary"
            className="tax-manager-submit-button"
            onClick={handleSubmit}
            loading={isLoading}
          >
            Submit
          </Button>
          {/* </div> */}
        </div>
      </form>
    </div>
  );
};

export default AddPanComponent;
