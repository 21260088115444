import MasterLayout from ".";
import { Navigate } from "react-router-dom";
import Test1 from "app/scenes/Auth/components/Test1";
import Test2 from "app/scenes/Auth/components/Test2";
import ProtectedRoute from "app/utils/ProtectedRoute";
import credentialsRoutes from "app/scenes/Auth/components/Test2";
import DummyComponent from "app/scenes/DummyComponent";
import Dashboard from "../Dashboard";
import UserDetails from "../UserDetails";
import InvoiceRequest from "../InvoiceRequest";
import Onboarding from "../../scenes/Onboarding";
import CompanyDetail from "../../shared/UserDetailsForm/CompanyDetailsForm";
import TaxManager from "../../shared/UserDetailsForm/TaxManagerForm";
import TravelContact from "../../shared/UserDetailsForm/TravelContact";
import BoxUI from "../BoxUI";
import InviteUser from "../../shared/InviteUser";
import AddPan from "../../shared/AddPan";
import InviteTmc from "../../shared/InviteTmc";
import AddCompany from "../../shared/AddCompany";

const routes = {
  path: "/app",
  element: (
    // <ProtectedRoute>
    <MasterLayout />
    // </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "",
      element: <Dashboard />, // Default redirect to the first child route
      exact: true,
    },
    {
      path: "/app/userdetails",
      element: <UserDetails />,
      exact: true,
    },
    {
      path: "/app/invoicerequest",
      element: <InvoiceRequest />,
      exact: true,
    },
    {
      path: "/app/dashboard/onboarding",
      element: <Onboarding />,
      exact: true,
    },

    {
      path: "/app/invoices",
      element: <BoxUI />,
      exact: true,
    },

    {
      path: "/app/dashboard/companydetail",
      element: <CompanyDetail />,
      exact: true,
    },
    {
      path: "/app/dashboard/taxmanager",
      element: <TaxManager />,
      exact: true,
    },
    {
      path: "/app/dashboard/travelcontact",
      element: <TravelContact />,
      exact: true,
    },
    {
      path: "/app/inviteuser",
      element: <InviteUser />,
      exact: true,
    },
    {
      path: "/app/addpan",
      element: <AddPan />,
      exact: true,
    },
    {
      path: "/app/invitetmc",
      element: <InviteTmc />,
      exact: true,
    },
    {
      path: "/app/addcompany",
      element: <AddCompany />,
      exact: true,
    },
  ],
};

export default routes;
