import React from 'react';
import { InstagramOutlined, TwitterOutlined, LinkedinOutlined } from '@ant-design/icons';
import './Footer.scss';
import FinkraftLogo from '../../assets/images/FinLogo.png';



function PageFooter() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <nav className="footer-nav">
            <ul>
              <li><a href="#about">About</a></li>
              <li><a href="#features">Features</a></li>
              <li><a href="#testimonials">Testimonials</a></li>
            </ul>
          </nav>
          <div className="social-icons">
            <a href="#"><InstagramOutlined /></a>
            <a href="#"><TwitterOutlined /></a>
            <a href="#"><LinkedinOutlined /></a>
          </div>
        </div>
        <hr className="footer-divider" />
        <div className="footer-bottom">
          <p className="powered-by">
            powered by <img src={FinkraftLogo} alt='Finkraft logo' className='company-name'/>
          </p>
          <p className="copyright">
            © Copyright 2024 Finkraft | All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default PageFooter;