import React, { useEffect, useState } from "react";
import { message, Layout, Spin } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import "./MasterLayout.scss";
import PrimaryNav from "../PrimaryNav";
import PrimaryHeader from "../PrimaryHeader";

import Sidebar from "../Sidebar";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_META_USER_INFO } from "app/scenes/Auth/auth.constant";
import { apiGet } from "app/services/apiServices";

const MasterLayout = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  // return loading ? (
  //   <div className="LoadingContainer">
  //     <Spin size="large" />
  //   </div>
  // ) : (

  const fetchUserLogin = async () => {
    const response = await apiGet(API_ENDPOINT_META_USER_INFO);
    if (response.status) {
      setUserDetails(response.data);
      localStorage.setItem(
        "currentBoxId",
        response.data.workspaceInfo?.box_folder_id
      );
      navigate("/app");
    } else {
      message.error("Invalid credential");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserLogin();
  }, []);
  return (
    <div className="MainLayout">
      <div className="NavigationContainer">
        <PrimaryNav />
      </div>
      <div className="AppContentContainer">
        <div className="HeaderContainer">
          <PrimaryHeader />
        </div>
        <div className="PageContainer">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MasterLayout;
