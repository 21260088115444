import { useNavigate } from "react-router-dom";

import React, { useState } from "react";
import {
  Button,
  Card,
  Avatar,
  Modal,
  Checkbox,
  Form,
  Input,
  Select,
  Space,
} from "antd";

import { UserOutlined, IdcardOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "./UserDetails.scss";
import { ColDef } from "ag-grid-community";
import BuildingIcon from "../../assets/images/BuildingOffice.png";
import UserIcon from "../../assets/images/User.png";
import TrashIcon from "../../assets/images/TrashIcon.png";
import EditIcon from "../../assets/images/EditIcon.png";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_USER_INFO_UPDATE } from "app/scenes/Auth/auth.constant";

// Set the AG Grid Enterprise license key
LicenseManager.setLicenseKey("YOUR_LICENSE_KEY");

interface CompanyDetails {
  companyName: string;
  businessType: string;
  numberOfUsers: number;
}

interface PersonalDetails {
  name: string;
  email: string;
  mobile: string;
  businessType: string;
}

interface PanUser {
  id: string;
  name: string;
  avatar?: string;
}

interface TableData {
  name: string;
  emailAddress: string;
  mobile: string;
  joined: string;
  categories: string;
}

const CompanyDashboard: React.FC = () => {
  const [form] = Form.useForm();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowData, setRowData] = useState<any[]>([]);
  const navigate = useNavigate();

  const openEditModal = (row: any) => {
    setSelectedRow(row); // Set selected row data
    form.setFieldsValue(row); // Set form values based on selected row
    setIsModalVisible(true); // Show modal
  };

  const onFinish = (updatedValues: any) => {
    console.log("Form Submitted with Values:", updatedValues);

    const payload = { ...updatedValues, user_id: selectedRow.user_id };
    console.log("Payload + userId:", payload);

    // Update the rowData with the new values
    const updatedRowData = rowData.map((row) =>
      row.user_id === selectedRow.user_id ? { ...row, ...updatedValues } : row
    );

    setRowData(updatedRowData); // Update the state
    setIsModalVisible(false); // Close modal

    // Pass the updated values plus the user_id as payload
    handleTableUpdate(payload);
  };

  const handleTableUpdate = async (payload: any) => {
    console.log("Sending Payload :", payload);
    const response = await apiPost(
      `${API_ENDPOINT_USER_INFO_UPDATE}/${payload.user_id}`,
      payload
    );
    if (response.status) {
      console.log("Response from API:", response);
    } else {
      console.error("Error sending data:");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [userDetails, _] = useRecoilState<any>(userInfo);

  const companyDetails: CompanyDetails = {
    companyName: "Wipro Pvt Ltd",
    businessType: "Corporate",
    numberOfUsers: 85,
  };

  const personalDetails: PersonalDetails = {
    name: "Aman Mehta",
    email: "97travel@bigdigital.com",
    mobile: "+91 7846678457",
    businessType: "Corporate",
  };

  const panUsers: PanUser[] = [
    { id: "BAJPC4350M", name: "Aman Mehta" },
    { id: "XQZPE7225A", name: "Amit Gupta" },
    { id: "BAJPC4350M", name: "Rama Mehta" },
    { id: "XQZPE7225A", name: "Amit Gupta" },
    { id: "BAJPC4350M", name: "Rama Mehta" },
    { id: "XQZPE7225A", name: "Amit Gupta" },
    { id: "BAJPC4350M", name: "Rama Mehta" },
  ];

  const handleAddPanClick = () => {
    navigate("/app/addpan");
  };

  const columnDefs: ColDef[] = [
    { headerName: "Name", field: "name", sortable: true, filter: true },
    {
      headerName: "Email Address",
      field: "email",
      sortable: true,
      filter: true,
    },
    { headerName: "Mobile", field: "phone", sortable: true },
    // { headerName: "Joined", field: "joined", sortable: true },
    { headerName: "Categories", field: "account_type" },
    {
      headerName: "Action",
      cellRenderer: (params: any) => (
        <div className="action-buttons">
          <img
            src={EditIcon}
            alt="action-btn"
            onClick={() => openEditModal(params.data)}
          />
          <img src={TrashIcon} alt="action-btn" />
        </div>
      ),
    },
  ];

  const handleAddCompanyClick = () => {
    navigate("/app/addcompany"); // Navigate to the desired route
  };

  const onGridReady = (params: any) => {
    params.api.sizeColumnsToFit(); // Automatically adjust column widths
  };

  return (
    <div className="dashboard-container">
      <div className="info-cards">
        <div className="company-card">
          <div className="card-header">
            <div className="card-icon">
              <img src={BuildingIcon} alt="icon" />
            </div>
            <h3>Company Details</h3>
          </div>
          <div className="card-content">
            <div className="card-row">
              <div className="card-content-label"> Company Name: </div>
              <div className="card-content-value">
                {userDetails.workspaceInfo?.name}
              </div>{" "}
            </div>
            <div className="card-row">
              <div className="card-content-label">Business Type: </div>
              <div className="card-content-value">
                {" "}
                {userDetails.workspaceInfo?.type}
              </div>
            </div>
            <div className="card-row">
              <div className="card-content-label">No. of users added: </div>
              <div className="card-content-value">
                {" "}
                {(userDetails.tax_managers?.length || 0) +
                  (userDetails?.travel_contacts?.length || 0)}
              </div>
            </div>
          </div>
        </div>

        <div className="personal-card">
          <div className="card-header">
            <div className="card-icon">
              <img src={UserIcon} alt="icon" />
            </div>
            <h3>Personal Details</h3>
          </div>
          <div className="card-content">
            <div className="card-row">
              <div className="card-content-label">Name:</div>
              <div className="card-content-value">
                {userDetails?.userInfo?.name}
              </div>
            </div>
            <div className="card-row">
              <div className="card-content-label">Email:</div>
              <div className="card-content-value">
                {userDetails?.userInfo?.email}
              </div>
            </div>
            <div className="card-row">
              <div className="card-content-label">Mobile:</div>
              <div className="card-content-value">
                {userDetails?.userInfo?.phone}
              </div>
            </div>

            <div className="card-row">
              <div className="card-content-label">Business Type:</div>
              <div className="card-content-value">
                {userDetails?.userInfo?.account_type}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pan-users-section">
        <Button
          type="primary"
          className="add-pan-btn"
          onClick={handleAddPanClick}
        >
          {" "}
          + Add PAN
        </Button>
        <div className="pan-users-scroll">
          {(userDetails?.pans || []).map((pan: any) => (
            <div key={pan.pan} className="pan-user-card">
              <Avatar size="small" icon={<IdcardOutlined />} />
              <span>{pan.pan}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={[
            ...(userDetails?.tax_managers || []),
            ...(userDetails?.travel_contacts || []),
            ...(userDetails?.genericUsers || []),
          ]}
          pagination={true}
          paginationPageSize={10}
          onGridReady={onGridReady}
        />
      </div>

      <Modal
        title="Edit User Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={selectedRow} // Bind selected row data to form
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input the email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="phone"
            rules={[
              { required: true, message: "Please input the mobile number!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
          </Space>
        </Form>
      </Modal>

      <div className="add-corporates-section">
        <div className="content-text"> Corporate: </div>
        <Button
          type="primary"
          className="add-corporate-btn"
          onClick={handleAddCompanyClick}
        >
          + Add Company
        </Button>
      </div>
    </div>
  );
};

export default CompanyDashboard;
