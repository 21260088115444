import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { useNavigate } from "react-router-dom";
import BuildingIcon from "../../assets/images/BuildingOffice.png";
import UserIcon from "../../assets/images/User.png";
import AirplaneIcon from "../../assets/images/AirplaneTilt.png";
import {
  UserAddOutlined,
  FileAddOutlined,
  LockOutlined,
} from "@ant-design/icons";
import PhoneIcon from "../../assets/images/PhoneOutlined.png";
import PhoneHoverIcon from "../../assets/images/Phone-icon-hover.png";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { Button, Modal, Input, Form, message } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import { API_ENDPOINT_HELP_QUERY_SUBMIT } from "app/shared/MasterLayout/masterlayout.constant";
import { API_ENDPOINT_META_USER_INFO } from "app/scenes/Auth/auth.constant";
import Loader from "../Loader";

const RegistrationDashboard = () => {
  const [form] = Form.useForm();
  const [isHovered, setIsHovered] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [query, setQuery] = useState("");

  const showModal = () => {
    setOpen(true);
  };

  const fetchUserLogin = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_META_USER_INFO);
    if (response.status) {
      setUserDetails(response.data);
      navigate("/app");
    } else {
      message.error("Invalid credential");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserLogin();
  }, []);

  const handleOk = async () => {
    try {
      // Validate form fields
      const values = await form.validateFields();

      // Prepare payload
      const payload = {
        name: values.name,
        email: values.email,
        mobile: values.phone,
        query: values.query || "", // Make query optional
      };

      // Set loading state
      setConfirmLoading(true);

      // Make API call
      const response = await apiPost(API_ENDPOINT_HELP_QUERY_SUBMIT, payload);

      // Check API response
      if (response.status === true) {
        // Success scenario
        console.log("Callback request successful");
        message.success("Callback request submitted successfully");

        // Close modal and reset form
        setOpen(false);
        form.resetFields();
      } else {
        // Error scenario
        console.log("Callback request unsuccessful");
        message.error(response.message || "Failed to submit callback request");
      }
    } catch (errorInfo) {
      // Validation failed
      console.log("Validation Failed:", errorInfo);
      message.error("Please fill in all required fields");
    } finally {
      // Always reset loading state
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const handleCompanyDetailClick = () => {
    navigate("/app/dashboard/companydetail"); // Navigate to the desired route
  };

  const handleTaxManagerClick = () => {
    navigate("/app/dashboard/taxmanager"); // Navigate to the desired route
  };

  const handleTravelContactClick = () => {
    navigate("/app/dashboard/travelcontact"); // Navigate to the desired route
  };

  const handleInviteUserClick = () => {
    navigate("/app/inviteuser");
  };

  const handleAddPanClick = () => {
    navigate("/app/addpan");
  };

  const handleInviteTmcClick = () => {
    navigate("/app/invitetmc");
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="registration-container">
      {/* Action Buttons */}
      <div className="action-buttons">
        <button className="action-btn" onClick={handleInviteUserClick}>
          <UserAddOutlined />
          Invite User
        </button>
        <button className="action-btn" onClick={handleAddPanClick}>
          <FileAddOutlined />
          Add PAN
        </button>
        <button className="action-btn" onClick={handleInviteTmcClick}>
          <UserAddOutlined />
          Invite TMC
        </button>
        <button
          className="action-btn primary"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={showModal}
        >
          <img src={isHovered ? PhoneHoverIcon : PhoneIcon} alt="phone" />
          Request a call back
        </button>
      </div>

      <Modal
        title="Request a Callback"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Submit Request"
        okButtonProps={{
          style: {
            backgroundColor: "#74003B", // Vibrant green color
            borderColor: "#74003B",
          },
        }}
      >
        <Form form={form} layout="vertical" name="callback_request_form">
          <Form.Item
            name="name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: "Please enter your full name",
              },
            ]}
          >
            <Input
              type="text"
              defaultValue=" "
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your full name"
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter your email address",
              },
            ]}
          >
            <Input
              type="text"
              defaultValue=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
            />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please enter your phone number",
              },
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]}
          >
            <Input
              type="text"
              defaultValue=" "
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter 10-digit phone number"
              addonBefore="+91"
            />
          </Form.Item>

          <Form.Item name="query" label="Write your query here (Optional)">
            <Input.TextArea
              rows={3}
              defaultValue=" "
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Briefly describe your query or concern"
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Instructions */}
      <p className="instructions">
        Please fill out these forms to complete the registration process and
        access your invoices.
      </p>

      {/* Status Cards */}
      <div className="status-cards">
        <div className="status-card">
          <div className="card-icon company">
            <div className="icon">
              <img src={BuildingIcon} alt="icon" />
            </div>
          </div>
          <div className="card-content">
            <h3>Company Details</h3>
            <div className="status-row">
              <span
                className={
                  "status " +
                  (userDetails?.workspaceInfo ? " active" : "pending")
                }
              >
                {userDetails?.workspaceInfo ? "COMPLETED" : "Pending"}
              </span>
              <button className="edit-btn" onClick={handleCompanyDetailClick}>
                {userDetails?.workspaceInfo ? "Edit" : "Pending"}
              </button>
            </div>
          </div>
        </div>

        <div className="status-card">
          <div className="card-icon tax">
            <div className="icon">
              <img src={UserIcon} alt="icon" />
            </div>
          </div>
          <div className="card-content">
            <h3>Tax Manager</h3>
            <div className="status-row">
              <span
                className={
                  "status " +
                  (userDetails?.tax_managers?.length > 0
                    ? " active"
                    : " pending")
                }
              >
                {userDetails?.tax_managers?.length > 0
                  ? "COMPLETED"
                  : "Pending"}
              </span>

              {userDetails?.workspaceInfo ? (
                <button
                  className="complete-btn"
                  onClick={handleTaxManagerClick}
                >
                  Add more
                </button>
              ) : (
                <button className="complete-btn">
                  <LockOutlined style={{ marginRight: 4 }} />
                  Locked
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="status-card">
          <div className="card-icon travel">
            <div className="icon">
              <img src={AirplaneIcon} alt="icon" />
            </div>
          </div>
          <div className="card-content">
            <h3>Travel Contact</h3>
            <div className="status-row">
              <span
                className={
                  "status " +
                  (userDetails?.travel_contacts?.length > 0
                    ? " active"
                    : " pending")
                }
              >
                {userDetails?.travel_contacts?.length > 0
                  ? "COMPLETED"
                  : "Pending"}
              </span>
              {userDetails?.tax_managers?.length > 0 ? (
                <button
                  className="complete-btn"
                  onClick={handleTravelContactClick}
                >
                  Add more
                </button>
              ) : (
                <button className="complete-btn">
                  <LockOutlined style={{ marginRight: 4 }} />
                  Locked
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Notification */}
      <div className="notification">
        <p>
          Hi {userDetails?.userInfo?.name}, Complete your GST reconciliation
          early and enjoy additional support services.
          <a href="#"> Click here</a> for details.
        </p>
      </div>

      {/* View Invoices Button */}
      <button className="view-invoices-btn">View Invoices</button>
    </div>
  );
};

export default RegistrationDashboard;
