import React from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import "./PrimaryNav.scss";
import QatarLogo from "../../assets/images/qatar-airways-1 (1) 1.png";
import {
  FileOutlined,
  UserOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import DashboardIcon from "../../assets/images/SquaresFour.png";
import FileIcon from "../../assets/images/File.png";
import UserIcon from "../../assets/images/UserCircle.png";
import InvoiceRequestIcon from "../../assets/images/Invoice.png";
import DashboardInactiveIcon from "../../assets/images/dashboard-inactive.png";
import UserActiveIcon from "../../assets/images/UserCircle-active.png";
import InvoiceInactiveIcon from "../../assets/images/InvoiceInactiveIcon.png";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import UserIconColored from "../../assets/images/UserCircle (1).png";
import { Button, Dropdown , Divider} from 'antd';
import type { MenuProps } from 'antd';

export default function PrimaryNav() {
  const location = useLocation();
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const navigate = useNavigate();


  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const profileItems: MenuProps = {
    items: [
    {
      key: '1',
      label: (
        <>
        <div className="UserInfo" style={{ color: "gray" }}>
          {userDetails?.userInfo?.name}
        </div>
        {/* <Divider style={{ margin: 0, marginTop: 15 }} /> */}

        </>

      ),
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      key: "2",
      danger: true,
      label: <div onClick={handleLogout}>Logout</div>,
    }
  ]
}

  return (
    <div className="navbar">
      <div className="navbar-menu">
        <ul>
          <li className={location.pathname === "/app" ? "active" : ""}>
            <Link to="/app">
              <img
                src={
                  location.pathname === "/app"
                    ? DashboardIcon
                    : DashboardInactiveIcon
                }
                alt="icon"
              />
              Dashboard
            </Link>
          </li>
          {/* <li className={location.pathname === "/app/doc" ? "active" : ""}>
            <Link to="/app/doc">
              <img
                src={location.pathname === "/app/doc" ? FileIcon : FileIcon}
                alt="icon"
              />
              Documents
            </Link>
          </li> */}
          <li
            className={location.pathname === "/app/userdetails" ? "active" : ""}
          >
            <Link to="/app/userdetails">
              <img
                src={
                  location.pathname === "/app/userdetails"
                    ? UserActiveIcon
                    : UserIcon
                }
                alt="icon"
              />
              User Details
            </Link>
          </li>
          {userDetails?.userInfo?.status === "ACCEPTED" ? (
            <li
              className={location.pathname === "/app/invoices" ? "active" : ""}
            >
              <Link to="/app/invoices">
                {/* <img
                src={
                  location.pathname === "/app/userdetails"
                    ? UserActiveIcon
                    : UserIcon
                }
                alt="icon"
              /> */}
                <FileDoneOutlined style={{ fontSize: 22, marginBottom: 8 }} />
                Invoices
              </Link>
            </li>
          ) : null}
          {userDetails?.userInfo?.email?.includes("kgrp.in") ||
          userDetails?.userInfo?.email?.includes("finkraft.ai") ? (
            <li
              className={
                location.pathname === "/app/invoicerequest" ? "active" : ""
              }
            >
              <Link to="/app/invoicerequest">
                <img
                  src={
                    location.pathname === "/app/invoicerequest"
                      ? InvoiceRequestIcon
                      : InvoiceInactiveIcon
                  }
                  alt="icon"
                />
                Invoice Requests
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
      <div className="profile-btn-container">
      <Dropdown 
      menu={ profileItems } 
      trigger={['click']}
      arrow
      >     
      <Button className="avatar-btn profile-btn" style={{ zIndex: 99999, marginBottom: 0 }}>
            <img src={UserIconColored} alt="icon" />
            {/* <span>{userDetails?.userInfo?.name}</span> */}
      </Button>
      </Dropdown>
      </div>
    </div>
  );
}
