import LandingPage from "./LandingPage";
import RegisterPage from "./RegisterNow";
import LogInPage from "./LogIn";
import SalesPage from "./SalesPage";

export default {
  path: "/",
  element: <SalesPage />,
  strict: true,
  children: [
    {
      path: "",
      element: <LandingPage />,
      exact: true,
    },
    {
      path: "/register",
      element: <RegisterPage />,
      exact: true,
    },
    {
      path: "/login",
      element: <LogInPage />,
      exact: true,
    }
  ],
}
