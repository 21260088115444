import { useRoutes } from "react-router-dom";
import RouteMap from './app/config/States/routeConfig'


const Entry = () => {
  const routes = useRoutes([...RouteMap]);
  return routes;
};

export default Entry;
