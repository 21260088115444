import React, { useState } from "react";
import MainImage from "../../assets/images/form-2.png";
import "./InviteUser.scss";
import { Button, Input, message } from "antd";
import ShieldIcon from "../../assets/images/shield-tick-safe (1).png";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER } from "app/shared/MasterLayout/masterlayout.constant";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { useNavigate } from "react-router-dom";

const TaxManagerComponent: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const [linkedinProfileLink, setLinkedinProfileLink] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      name,
      email,
      phone: mobile,
      linkedinProfile,
      workspaceId: userDetails?.workspaceInfo?.id,
      type: "GENERAL_USER",
    };
    console.log("payload is:", payload);

    const response = await apiPost(
      (API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER),
      payload
    );
    if (response.status) {
      setName("");
      setEmail("");
      setMobile("");
      setLinkedinProfile("");
      message.success("User Invite Sent!");
      navigate("/app");
      
    } else {
      message.error("Could not invite user");
    }
    setLoading(false);
    // Here you can handle the form submission, e.g., send the data to a backend
  };

  return (
    <div className="tax-manager-container">
      <div className="tax-manager-image-container">
        <img src={MainImage} alt="Tax Manager" className="tax-manager-image" />
        <div className="image-heading">Invite User</div>
        <div className="image-text">
        Invite your team members and partners to join the platform, fostering collaboration and driving collective success seamlessly..{" "}
        </div>
      </div>

      <div className="tax-manager-info-container">
        {/* <div className="skip-btn-container">
          <Button type="primary" className="tax-manager-skip-button">
            Skip
          </Button>
        </div> */}

        <div className="form-header">Invite User</div>
        <div className="form-group">
          <label htmlFor="email">Name *</label>
          <Input
            type="text"
            defaultValue=" "
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            style={{ borderColor: "#C3C4CD" }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email *</label>
          <Input
            type="text"
            defaultValue=" "
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            style={{ borderColor: "#C3C4CD" }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Mobile *</label>
          <Input
            type="text"
            defaultValue=" "
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            placeholder="Enter Mobile Number"
            style={{ borderColor: "#C3C4CD" }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">LinkedIn profile link *</label>
          <Input
            type="text"
            defaultValue=" "
            value={linkedinProfile}
            onChange={(e) => setLinkedinProfile(e.target.value)}
            placeholder="LinkedIn profile link"
            style={{ borderColor: "#C3C4CD" }}
          />
        </div>
        <div className="info-text">
          <img src={ShieldIcon} alt="shield icon" />
          <p>No spams your information is safe with us!</p>
        </div>
        <div className="btn-group">
          {/* <Button type="primary" className="tax-manager-add-more-button">
            Add More
          </Button> */}
          <Button
            type="primary"
            className="tax-manager-submit-button"
            onClick={handleSubmit}
            loading={isLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TaxManagerComponent;
