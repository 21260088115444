import React, { useState } from "react";
import MainImage from "../../../assets/images/form-1&3.png";
import "./TravelContact.scss";
import { Button, Input, message } from "antd";
import ShieldIcon from "../../../assets/images/shield-tick-safe (1).png";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER } from "app/shared/MasterLayout/masterlayout.constant";
import { apiPost } from "app/services/apiServices";

const TaxManagerComponent: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  // const [linkedinProfileLink, setLinkedinProfileLink] = useState('');
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      name,
      email,
      phone: mobile,
      linkedinProfile,
      workspaceId: userDetails?.workspaceInfo?.id,
      type: "TRAVEL_CONTACT",
    };
    console.log("payload is:", payload);

    const response = await apiPost(
      API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER,
      payload
    );
    if (response.status) {
      setName("");
      setEmail("");
      setMobile("");
      setLinkedinProfile("");
      message.success("Travel contact invited successfully!");
    } else {
      message.error("Could not invite travel manager");
    }
    setLoading(false);
    // Here you can handle the form submission, e.g., send the data to a backend
  };
  return (
    <div className="tax-manager-container">
      <div className="tax-manager-image-container">
        <img src={MainImage} alt="Tax Manager" className="tax-manager-image" />
        <div className="image-heading">Travel Contact</div>
        <div className="image-text">
          We would like to know about you as the invoice details are
          confidential. This will be a one time mandatory registration process
          and based on which you will be able to access/download the invoices
          after validation.{" "}
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="tax-manager-info-container">
          <div className="skip-btn-container">
            <Button type="primary" className="tax-manager-skip-button">
              Skip
            </Button>
          </div>

          <div className="form-header">Travel Contact</div>
          <div className="form-group">
            <label htmlFor="email">Name *</label>
            <Input
              type="text"
              defaultValue=" "
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              style={{ borderColor: "#C3C4CD" }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <Input
              type="text"
              defaultValue=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email address"
              style={{ borderColor: "#C3C4CD" }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Mobile *</label>
            <Input
              type="text"
              defaultValue=" "
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter Mobile Number"
              style={{ borderColor: "#C3C4CD" }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">LinkedIn profile link *</label>
            <Input
              type="text"
              defaultValue=" "
              value={linkedinProfile}
              onChange={(e) => setLinkedinProfile(e.target.value)}
              placeholder="LinkedIn profile link"
              style={{ borderColor: "#C3C4CD" }}
            />
          </div>
          <div className="info-text">
            <img src={ShieldIcon} alt="shield icon" />
            <p>No spams your information is safe with us!</p>
          </div>
          <div className="btn-group">
            <Button type="primary" className="tax-manager-add-more-button">
              Add More
            </Button>

            <Button
              type="primary"
              className="tax-manager-submit-button"
              loading={isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TaxManagerComponent;
