import React, { useEffect, useRef, useState } from "react";
import { Input, Dropdown, Space, MenuProps, Button, message, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import "./InvoiceRequest.scss";
import {
  API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_LIST,
  API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_UPDATE,
} from "../MasterLayout/masterlayout.constant";
import { apiGet, apiPost } from "app/services/apiServices";
import { debounce } from "lodash";

// LicenseManager.setLicenseKey('YOUR_LICENSE_KEY');

const { Search } = Input;

interface BusinessData {
  name: string;
  email: string;
  mobile: string;
  company: string;
  iataCode: string;
  gstin: string;
  requested: string;
}

const BusinessTable: React.FC = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [backupData, setBackupData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const gridRef = useRef<any>(null);
  const [searchText, setSearchText] = useState(""); // Controlled input state

  useEffect(() => {
    fetchTableData();
  }, []);
  const fetchTableData = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_LIST
    );
    if (response.status) {
      setTableData(response.data);
      setBackupData(response.data);
    }
    setLoading(false);
  };

  const businessTypeItems: MenuProps["items"] = [
    {
      label: <a>Corporate</a>,
      key: "corporate",
    },
    {
      label: <a>Individual</a>,
      key: "individual",
    },
    {
      type: "divider",
      key: "divider",
    },
    {
      label: <a>Other</a>,
      key: "other",
    },
  ];

  const handleManageRequest = async (requestId: string, status: string) => {
    const response = await apiPost(
      API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_UPDATE,
      {
        requestId: requestId,
        status: status,
      }
    );
    if (response.status) {
      message.success("Invoice Request accepted");
    } else {
      message.error("Invoice Request rejected");
    }
  };
  const columnDefs: ColDef[] = [
    { field: "user_name", headerName: "Name", sortable: true, filter: true },
    { field: "user_email", headerName: "Email", sortable: true, filter: true },
    { field: "entity_name", headerName: "Company", sortable: true },

    {
      headerName: "Requested",
      field: "requested",
      cellRenderer: (params: any) => {
        return params.data.invoice_status === "ACCEPTED" ? (
          <Tag color="green">ACCPETED</Tag>
        ) : params.data.invoice_status === "REJECTED" ? (
          <Tag color="red">REJECTED</Tag>
        ) : (
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#d8ffc5",
                borderColor: "#329900",
                color: "#329900",
                fontSize: "12px",
                width: "35%",
                marginTop: "2px",
                marginLeft: "15%",
              }}
              onClick={() =>
                handleManageRequest(params.data.invoice_id, "ACCEPTED")
              }
            >
              ✓ Accept
            </Button>
            <Button
              danger
              style={{
                backgroundColor: "#fecece",
                borderColor: "#ff4d4f",
                fontSize: "12px",
                width: "35%",
                marginTop: "2px",
              }}
              onClick={() =>
                handleManageRequest(params.data.invoice_id, "REJECTED")
              }
            >
              ☓ Decline
            </Button>
          </div>
        );
      },
    },
  ];

  const onGridReady = (params: any) => {
    params.api.sizeColumnsToFit(); // Automatically adjust column widths
    gridRef.current = params.api;
  };

  // Handler to update the quick filter text
  const onQuickFilterTextChange = (e: any) => {
    if (gridRef.current) {
      gridRef.current.setQuickFilter(e.target.value);
    }
  };

  const handleSearch = debounce((text: any) => {
    const filteredData = backupData.filter(
      (row) =>
        row.name.toLowerCase().includes(text.toLowerCase()) ||
        row.email.toLowerCase().includes(text.toLowerCase())
    );
    setTableData(filteredData);
  }, 300);

  // Update searchText and call debounced search function
  const onSearchChange = (e: any) => {
    const text = e.target.value;
    setSearchText(text);
    handleSearch(text);
  };

  useEffect(() => {
    // Cleanup the debounce when component unmounts
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  return (
    <div className="business-table">
      <div className="invoice-request-header">
        <div className="search-container">
          <div className="search-text"> Search</div>

          <Search
            placeholder="Search"
            className="search-bar"
            style={{ borderColor: "#C3C4CD" }}
            value={searchText}
            onChange={onSearchChange}
          />
        </div>

        {/* <div className="dropdown-container">
          <div className="dropdown-text">Business Types</div>
          <Dropdown
            className="dropdown-box"
            menu={{ items: businessTypeItems }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Corporate
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div> */}
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: 400, width: "97%", marginLeft: "25px" }}
      >
        <AgGridReact
          rowData={tableData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={10}
          onGridReady={onGridReady}
          ref={gridRef}
        />
      </div>
    </div>
  );
};

export default BusinessTable;
