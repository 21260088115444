
import React from 'react';

const Test2: React.FC = () => {
  return (
    <div>
      <h1>Test 2 Component</h1>
      <p>This is the content of Test 2.</p>
    </div>
  );
};

export default Test2;
