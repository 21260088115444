import React from 'react';
import './AboutUs.scss';
import AboutUsImg from '../../../assets/images/about-us-img.png'
import { Button } from "antd";
const AboutUs = () => {
  return (
    <div className="about-us-container" id="about-us-section">
      <div className="about-us-content">
        <h1>About Us</h1>
        <h2>GST Invoices Portal</h2>
        <p>Krafting reliable automated solutions to make complex business processes easier</p>
        <p>All about section 16 of GST !!</p>
        <Button className='know-more-btn' style={{borderColor:'#46003B', color:'#434343', fontSize: '17px', marginTop:'10px'}}>Know more</Button>
      </div>
      <div className="about-us-image">
        <img src={AboutUsImg} alt="Person reviewing invoice" />
      </div>
    </div>
  );
};

export default AboutUs;