import React, { useState } from 'react';
import { Button, message, Steps, theme } from 'antd';
import CompanyDetail from '../../shared/UserDetailsForm/CompanyDetailsForm';
import TaxManager from '../../shared/UserDetailsForm/TaxManagerForm';
import TravelContact from '../../shared/UserDetailsForm/TravelContact'

const steps = [
  {
    title: 'Company Detail',
  },
  {
    title: 'Tax Manager',
  },
  {
    title: 'Travel Contact',  
  },
];

const stepContent:any={
0:<CompanyDetail/>,
1:<TaxManager/>,
2:<TravelContact/>
}

const Onboarding: React.FC = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <div className='onboarding'>
      <div className='onboarding-stepper'>
      <Steps current={current} items={items} />

      </div>
      <div className='onboarding-content'>
        {stepContent[current]}
      </div>

      

    </div>
  );
};

export default Onboarding;