import React from 'react';
import './InvoiceFeatures.scss';
import DocumentIcon from '../../assets/images/document-icon.png';
import BagIcon from '../../assets/images/bag-icon.png';
import DownloadIcon from '../../assets/images/download-icon.png';

const GSTInvoiceFeatures: React.FC = () => {
  return (
    <div className="invoice-feature-container">
      <p className="gst-invoice-info">
        GST tax invoices will be processed as per GSTIN details provided at the time of booking and will be mailed within four days to the email address provided at the time of booking.
      </p>
      <div className="feature-cards">
        <div className="feature-card">
          <div className="icon document-icon">
            <img src={DocumentIcon} alt='document'/>
          </div>
          <h3>Download your GST Invoice</h3>
          <p>Download Tax Invoices for travel and cargo</p>
        </div>
        <div className="feature-card">
          <div className="icon bag-icon">
          <img src={BagIcon} alt='document'/>
          </div>
          <h3>Upload Travel details</h3>
          <p>Ensure correct GST details get captured & no loss of GST Credit</p>
        </div>
        <div className="feature-card">
          <div className="icon download-icon">
          <img src={DownloadIcon} alt='document'/>
          </div>
          <h3>Download a compilation</h3>
          <p>Download various reports for reconciliation</p>
        </div>
      </div>
     
    </div>
  );
};

export default GSTInvoiceFeatures;