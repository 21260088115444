import { atom } from "recoil";

export const workspaceInfo: any = atom({
  key: "workspaceInfo",
  default: null,
});

export const userInfo: any = atom({
  key: "userInfo",
  default: {},
});

export const onboardInfo: any = atom({
  key: "onboardInfo",
  default: {},
});
