import PageHeader from "app/shared/PageHeader";
import "./BoxUI.scss";

import { useEffect, useRef, useState } from "react";
import { apiGet } from "app/services/apiServices";
import Loader from "app/shared/Loader";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { Empty } from "antd";
export default function BoxUI(props) {
  const [userDetails, _] = useRecoilState(userInfo);
  const [isLoading, setLoading] = useState(false);
  console.log("userDetails", userDetails);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [userDetails.currentBoxId]);
  return (
    <div className="BoxUI" style={{ height: "100%" }}>
      {isLoading ? (
        <Loader />
      ) : userDetails?.workspaceInfo?.box_folder_id ? (
        <iframe
          src="../../BoxWidget.html" // Path to your HTML file in the public directory
          width="100%" // Adjust width as needed
          // Adjust height as needed
          title="Embedded HTML"
          style={{ border: "none", height: "100%" }} // Optional styling
        />
      ) : (
        <Empty />
      )}
    </div>
  );
}
