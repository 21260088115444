import React from "react";
import "./PrimaryHeader.scss";
import { UserOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import QatarLogo from "../../assets/images/qatar-airways-1 (1) 1.png";
import UserIconColored from "../../assets/images/UserCircle (1).png";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { Link } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import SearchDropdown from "../WorkspaceSelector";
import { API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE } from "app/shared/MasterLayout/masterlayout.constant";
import { apiGet } from "app/services/apiServices";


const Header = () => {
  const [userDetails, setUserDetails ] = useRecoilState<any>(userInfo);
  console.log("userDetails", userDetails);

  const handleSelectWorkspce = async (workspaceInfo : any) => {
    const updatedUserDetails = {
      ...userDetails,
      currentWorkspace: workspaceInfo,
    };
    setUserDetails(updatedUserDetails);
    localStorage.setItem("currentWorkspace", workspaceInfo.id);

    // Fetch additional user info
    const response = await apiGet(API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE);
    if (response?.status) {
      setUserDetails({
        ...response.data,
        currentWorkspace: workspaceInfo,
      });
    }
  };

  return (
    <header className="top-header">
      <div className="header-content">
        <div className="logo">
          <Link to="/app">
            <img src={QatarLogo} alt="Qatar Airways" />
          </Link>
        </div>

        <div className="header-actions">
          {/* <button className="action-btn">FAQ</button> */}

          <button className="action-btn">Help</button>

          {/* <button className="action-btn profile-btn">
            <img src={UserIconColored} alt="icon" />
            <span>{userDetails?.userInfo?.name}</span>
          </button> */}

          <div className="ItemContainer" style={{ width: "fit-content" }}>
            <SearchDropdown
               workspaceList={userDetails?.workspaceList || []}
              handleSelectWorkspce={handleSelectWorkspce}
              userData={userDetails}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
