
import React from 'react';

const Test1: React.FC = () => {
  return (
    <div>
      <h1>Test 1 Component</h1>
      <p>This is the content of Test 1.</p>
    </div>
  );
};

export default Test1;
