import authRoute from "app/scenes/Auth/auth.routes"; 
import salespageRoute from "app/scenes/SalesPage/salespage.routes";
import masterRoutes from 'app/shared/MasterLayout/master.routes';
import LandingPage from "app/scenes/SalesPage/LandingPage";
import path from "path";
const childRoutes = [authRoute, salespageRoute, masterRoutes]
const routeConfig = [
    ...childRoutes,
    {
      path: "*",
      element: <LandingPage />,
      strict: true,
    },
];


export default routeConfig; 
