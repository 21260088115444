import React from 'react';
import './HelpSection.scss';
import SupportAgent from '../../assets/images/support-agent.png';
import LocationIcon from '../../assets/images/EnvironmentOutlined.png';
import PhoneIcon from '../../assets/images/PhoneOutlined.png';
import MailIcon from '../../assets/images/MailOutlined.png';

const HelpSection= () => {
  return (
    <div className="help-container" id='help-section'>
      <h2>Still need help?</h2>
      <p>Call us or mail us, we will get back to you</p>
      <div className="content-wrapper">
        <div className="contact-info">
          <div className="info-item">
           <img src={LocationIcon} alt='location'/>
            <span>India</span>
          </div>
          <div className="info-item">
          <img src={PhoneIcon} alt='phone'/>
            <span>+919659303309</span>
          </div>
          <div className="info-item">
          <img src={MailIcon} alt='mail'/>
            <span>support@finkraft.ai</span>
          </div>
        </div>
        <div className='customer-support-container'>
          <div className="image-container">
          <img src={SupportAgent} alt="Customer support agent" />
          </div>
          <div className="support-options">
          <h3>Talk to an agent</h3>
          <p>Get help with our support system</p>
          <button className="connect-button">Let's connect</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpSection;