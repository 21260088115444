export default {
    primary: "#0A8394",
    primary: {
      50: "#EBFFFE",
      100: "#CEFFFE",
      200: "#A2FEFF",
      300: "#63F9FD",
      400: "#1CEBF4",
      500: "#00DCE8",
      600: "#03A5B7",
      700: "#0A8394",
      800: "#126978",
      900: "#145765",
      950: "#063A46",
    },
    secondary: "#7676D3",
    grayLight: "#2B2B2B99",
    green: {
      50: "#F1FCF3",
      100: "#DFF9E5",
      200: "#C0F2CC",
      300: "#8FE6A3",
      400: "#57D174",
      500: "#30B752",
      600: "#22973F",
      700: "#1E7734",
      800: "#1D5E2E",
      900: "#194E28",
      950: "#082B13",
    },
    red: {
      50: "#FFF2F1",
      100: "#FFE1DF",
      200: "#FFC8C5",
      300: "#FFA29D",
      400: "#FF6C64",
      500: "#FF3E34",
      600: "#ED2015",
      700: "#C8170D",
      800: "#A5170F",
      900: "#881A14",
      950: "#4B0804",
    },
    yellow: {
      50: "#FFFEE7",
      100: "#FFFEC1",
      200: "#FFF886",
      300: "#FFEC41",
      400: "#FFDB0D",
      500: "#FFCC00",
      600: "#D19500",
      700: "#A66A02",
      800: "#89530A",
      900: "#74430F",
      950: "#442304",
    },
    orange: {
      50: "#FFFBEA",
      100: "#FFF1C5",
      200: "#FFE485",
      300: "#FFCF46",
      400: "#FFB91B",
      500: "#FF9500",
      600: "#E26E00",
      700: "#BB4A02",
      800: "#983908",
      900: "#7C2F0B",
      950: "#481600",
    },
    neutral: {
      50: "#FFFFFF",
      100: "#F8F9FB",
      200: "#EDEFF5",
      300: "#E8EAF1",
      400: "#DFE0E7",
      500: "#C3C4CD",
      600: "#A3A6B7",
      700: "#72747D",
      800: "#3F4047",
      900: "#2C2C30",
      950: "#212121",
    },
  };