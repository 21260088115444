import React, { useState } from 'react';
import './FAQ.scss';

interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "When will we get the cargo and passenger invoices?",
    answer: "Corporate passengers get invoices in 35-40 days and cargo in 22 days."
  },
  {
    question: "Where should I provide GST details?",
    answer: "Goods and Services Tax (GST) is a destination-based tax on consumption of goods and services. GST would be applicable on Transport of Passengers by Air. GST will be effective from 01 July 2017 for tickets issued worldwide wherein travel originates in India regardless of the point of sale."
  },
  {
    question: "What is the procedure for getting invoices?",
    answer: "Goods and Services Tax (GST) is a destination-based tax on consumption of goods and services. GST would be applicable on Transport of Passengers by Air. GST will be effective from 01 July 2017 for tickets issued worldwide wherein travel originates in India regardless of the point of sale."
  },
  {
    question: "Is it compulsory to provide GST registration details?",
    answer: "Goods and Services Tax (GST) is a destination-based tax on consumption of goods and services. GST would be applicable on Transport of Passengers by Air. GST will be effective from 01 July 2017 for tickets issued worldwide wherein travel originates in India regardless of the point of sale."
  },
  {
    question: "When will I receive the GST invoice for my ticket?",
    answer: "Goods and Services Tax (GST) is a destination-based tax on consumption of goods and services. GST would be applicable on Transport of Passengers by Air. GST will be effective from 01 July 2017 for tickets issued worldwide wherein travel originates in India regardless of the point of sale."
  },
  {
    question: "May I provide my credential to other users?",
    answer: "Goods and Services Tax (GST) is a destination-based tax on consumption of goods and services. GST would be applicable on Transport of Passengers by Air. GST will be effective from 01 July 2017 for tickets issued worldwide wherein travel originates in India regardless of the point of sale."
  },
  {
    question: "Can I download my invoice without a ticket number?",
    answer: "Goods and Services Tax (GST) is a destination-based tax on consumption of goods and services. GST would be applicable on Transport of Passengers by Air. GST will be effective from 01 July 2017 for tickets issued worldwide wherein travel originates in India regardless of the point of sale."
  }
];

const FAQComponent= () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div id='faq-section'>
    <h2 className="faq-title">Frequently asked questions</h2>
    <div className="faq-container">
      
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <button
            className="faq-question"
            onClick={() => toggleQuestion(index)}
          >
            <span>{faq.question}</span>
            <span className={`faq-icon ${openIndex === index ? 'open' : ''}`}>
              {openIndex === index ? '−' : '+'}
            </span>
          </button>
          {openIndex === index && (
            <p className="faq-answer">{faq.answer}</p>
          )}
        </div>
      ))}
    </div>
    </div>
  );
};

export default FAQComponent;