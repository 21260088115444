import React, { useEffect, useState } from "react";
import MainImage from "../../assets/images/form-1&3.png";
import "./InviteTmc.scss";
import { Button, Input, message } from "antd";
import ShieldIcon from "../../assets/images/shield-tick-safe (1).png";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE } from "app/shared/MasterLayout/masterlayout.constant";
import { useNavigate } from "react-router-dom";

const InviteTmcComponent: React.FC = () => {
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [PAN, setPAN] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  // const [linkedinProfileLink, setLinkedinProfileLink] = useState('');
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   setName(userDetails.workspaceInfo?.name);
  //   setPAN(userDetails.pans?.[0]?.pan);
  //   setWebsite(userDetails?.workspaceInfo?.website);
  // }, [userDetails]);

  const handleFirstFormSubmit = () => {
    // Basic validation for first step
    if (!name || !website) {
      message.error("Please fill in all required fields");
      return;
    }
    setCurrentStep(2);
  };

  const handleSubmit = async () => {
    // Validate all fields
    if (!contactName || !contactEmail || !contactPhone) {
      message.error("Please fill in all required fields");
      return;
    }

    setLoading(true);
    const payload = {
      company: name,
      pan: PAN,
      website: website,
      type: userDetails?.userInfo?.account_type,
      contactName,
      contactEmail,
      contactPhone
    };

    try {
      const response = await apiPost(
        API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE,
        payload
      );
      if (response.status) {
        message.success("Invite to TMC has been sent");
        navigate("/app");
      } else {
        message.error("Error sending invite to TMC, try again");
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // const handleSubmit = async () => {
  //   setLoading(true);
  //   // Handle form submission logic
  //   const payload = {
  //     company: name,
  //     pan: PAN,
  //     website: website,
  //     type: userDetails?.userInfo?.account_type,
  //   };

  //   const response = await apiPost(
  //     API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE,
  //     payload
  //   );
  //   if (response.status) {
  //     message.success("Invite to TMC has been sent");
  //     navigate("/app");
  //   } else {
  //     message.error("Error sending invite to TMC, try again");
  //   }
  //   setLoading(false);
  // };

  const renderFirstForm = () => (
    <>
      <div className="form-header">Enter Company Details</div>
      <div className="form-group">
        <label htmlFor="company-name">Company Name *</label>
        <Input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter company name"
          style={{ borderColor: "#C3C4CD" }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="company-website">Company Website *</label>
        <Input
          type="text"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          placeholder="Enter company website"
          style={{ borderColor: "#C3C4CD" }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="pan-number">PAN Number</label>
        <Input
          type="text"
          value={PAN}
          onChange={(e) => setPAN(e.target.value)}
          placeholder="Enter PAN Number"
          style={{ borderColor: "#C3C4CD" }}
        />
      </div>
      <div className="info-text">
        <img src={ShieldIcon} alt="shield icon" />
        <p>No spams your information is safe with us!</p>
      </div>
      <Button
        type="primary"
        className="tax-manager-submit-button"
        onClick={handleFirstFormSubmit}
      >
        Next
      </Button>
    </>
  );

  const renderSecondForm = () => (
    <>
      <div className="form-header">Enter Contact Details of TMC</div>
      <div className="form-group">
        <label htmlFor="contact-name">Contact Name *</label>
        <Input
          type="text"
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          placeholder="Enter contact name"
          style={{ borderColor: "#C3C4CD" }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="contact-email">Contact Email *</label>
        <Input
          type="email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
          placeholder="Enter contact email"
          style={{ borderColor: "#C3C4CD" }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="contact-phone">Contact Phone *</label>
        <Input
          type="tel"
          value={contactPhone}
          onChange={(e) => setContactPhone(e.target.value)}
          placeholder="Enter contact phone number"
          style={{ borderColor: "#C3C4CD" }}
        />
      </div>
      <div className="info-text">
        <img src={ShieldIcon} alt="shield icon" />
        <p>No spams your information is safe with us!</p>
      </div>
      <Button
        type="primary"
        className="tax-manager-submit-button"
        onClick={handleSubmit}
        loading={isLoading}
      >
        Submit
      </Button>
    </>
  );


  return (
    <div className="tax-manager-container">
      <div className="tax-manager-image-container">
        <img src={MainImage} alt="Tax Manager" className="tax-manager-image" />
        <div className="image-heading">Invite TMC</div>
        <div className="image-text">
          We would like to know about you as the invoice details are
          confidential. This will be a one time mandatory registration process
          and based on which you will be able to access/download the invoices
          after validation.
        </div>
      </div>

      <form>
        <div className="tax-manager-info-container">
          {currentStep === 1 ? renderFirstForm() : renderSecondForm()}
        </div>
      </form>
    </div>
  );
};

export default InviteTmcComponent;
