import React from 'react';
import Header from '../../../shared/PageHeader';
import PageFooter from '../../../shared/PageFooter';
import HeroSection from '../HeroSection';
import AboutUs from '../AboutUs';
import ContactForm from '../QuerySection';
import './LandingPage.scss';

function LandingPage() {
  return (
    <div className="landing-page">
      <div className="page-header">
        <Header/>
        </div>

      <div className='page-body'>

      <HeroSection/>
      <AboutUs/>
      <ContactForm/>
      </div>
        

        <div className='page-footer'>
        <PageFooter/>
        </div>
        
      
    </div>
  );
}

export default LandingPage;
